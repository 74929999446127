import React, { ReactChildren } from 'react'
import styles from './Title.module.scss'
import classNames from 'classnames'

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p'

interface TitleProps {
    children: string | ReactChildren
    level?: HeadingLevel
    hasTopSpacing?: boolean
    hasBottomSpacing?: boolean
}

export default function Title({ children, level = 'p', hasTopSpacing, hasBottomSpacing }: TitleProps) {
    const Tag = level

    return (
        <Tag className={classNames({
            [styles['Title']]: true,
            [styles['Title--has-top-spacing']]: hasTopSpacing,
            [styles['Title--has-bottom-spacing']]: hasBottomSpacing
        })}>{children}</Tag>
    )

}