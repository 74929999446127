import React, { useRef, useState } from 'react'
import styles from './Image.module.scss'
import classNames from 'classnames'

interface ImageProps {
    src: string
    width: number | string
    height: number | string
}

export default function Image({ src, width, height }: ImageProps) {
    const [isLoaded, setIsLoaded] = useState(false)
    const parent = useRef<HTMLDivElement | null>(null)

    if (typeof width === 'string') width = parseInt(width)
    if (typeof height === 'string') height = parseInt(height)

    const aspectRatio = height / width

    return (
        <div
            className={classNames({
                [styles['Image']]: true,
                [styles['Image--is-loaded']]: isLoaded
            })}
            ref={parent}
        >
            <div
                className={styles['Image__inner']}
                style={{ paddingBottom: `${100 * aspectRatio}%` }}
            >
                <img
                    className={styles['Image__img']}
                    src={src}
                    alt="Just some fluff. Sorry to all screen reader users."
                    onLoad={() => setIsLoaded(true)}
                />
            </div>
        </div>
    )
}
