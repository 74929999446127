import React from 'react'
import styles from './LinkList.module.scss'
import classNames from 'classnames'

export interface ILinkListLink {
    label: string
    listSelectId?: string | number
    onClick: (event: any /**FIXME: ts */) => void
}

interface LinkListProps {
    title?: string
    items: ILinkListLink[]
    selected?: string | number
}

export default function LinkList({ title, items, selected }: LinkListProps) {
    if (items && items.length) {
        return (
            <div className={styles['LinkList']}>
                {title && (
                    <h3 className={styles['LinkList__title']}>{title}</h3>
                )}

                <ul className={styles['LinkList__list']}>
                    {items.map((item, i: number) => {
                        return (
                            <li key={i} className={styles['LinkList__item']}>
                                <button
                                    className={classNames({
                                        [styles['LinkList__button']]: true,
                                        [styles['LinkList__button--is-active']]:
                                            item.listSelectId === selected
                                    })}
                                    onClick={item.onClick}
                                >
                                    {item.label}
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    } else {
        return null
    }
}
