import React, { Dispatch, ReactChildren, SetStateAction } from 'react'
import styles from './Track.module.scss'
import classNames from 'classnames'

interface TrackProps {
    children: string | ReactChildren
    isActive?: boolean
    onClick?: () => void
}

export default function Track({ children, isActive, onClick }: TrackProps) {
    const Tag = onClick ? 'button' : 'p'

    return (
        <div>
            <Tag
                className={classNames({
                    [styles['Track']]: true,
                    [styles['Track--is-active']]: isActive,
                    [styles['Track--is-link']]: onClick
                })}
                onClick={() => onClick && onClick()}
            >
                {children}
            </Tag>
        </div>
    )
}
