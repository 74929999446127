// This file is a mess, everything here should be moved to its own files
// becuse now all kinds of different selectors, reducers and actions are
// mixed in a little hodgepodge ugh.....

import produce from 'immer'
import { FETCH_MIXES } from './views/Mixes/Mixes.actions'
import { FETCH_RELEASES } from './views/Releases/Releases.actions'
import { SET_ACTIVE_ROUTE } from './modules/App/App.actions'
import { FETCH_DATES } from './views/Dates/Dates.actions'
import { Dispatch } from 'redux'

export const LOADING_ROUTE_DATA = 'LOADING_ROUTE_DATA'
export const FINISHED_LOADING_ROUTE_DATA = 'FINISHED_LOADING_ROUTE_DATA'
export const HIDE_LOADER = 'HIDE_LOADER'
export const SET_NAV_LABEL = 'SET_NAV_LABEL'

export const getMainReducerState = (state: any /**FIXME: TS */) =>
    state.mainReducer

export const getLoadingState = (state: any /**FIXME: TS */) =>
    getMainReducerState(state).isLoadingRouteData

export const getLoaderVisibilityState = (state: any /**FIXME: TS */) =>
    getMainReducerState(state).showLoader

export const getActiveNavLabel = (state: any) => getMainReducerState(state).navLabel

export const removeLoader = () => (dispatch: Dispatch, getState: any) => {
    // dispatch({
    //     type: FINISHED_LOADING_ROUTE_DATA
    // })
    // dispatch({
    //     type: HIDE_LOADER
    // })

    setTimeout(() => {
        dispatch({
            type: HIDE_LOADER
        })
    }, 500)

    setTimeout(() => {
        dispatch({
            type: FINISHED_LOADING_ROUTE_DATA
        })
    }, 2500)
}

const initialState = {
    isLoadingRouteData: true,
    showLoader: true,
    releases: [],
    mixes: [],
    dates: [],
    activeRoute: 'Releases',
    navLabel: 'Releases'
}

export default function mainReducer(
    state = initialState,
    action: any /**FIXME: TS */
) {
    return produce(state, (draft) => {
        switch (action.type) {
            case LOADING_ROUTE_DATA:
                draft.isLoadingRouteData = true
                break

            case FINISHED_LOADING_ROUTE_DATA:
                draft.isLoadingRouteData = false
                break

            case HIDE_LOADER:
                draft.showLoader = false
                break

            case FETCH_RELEASES:
                draft.releases = action.payload
                break

            case FETCH_MIXES:
                draft.mixes = action.payload
                break

            case FETCH_DATES:
                draft.dates = action.payload
                break

            case SET_ACTIVE_ROUTE:
                draft.activeRoute = action.payload
                break

            case SET_NAV_LABEL:
                draft.navLabel = action.payload
                break

            default:
                break
        }
    })
}
