import React, { Dispatch, SetStateAction, useEffect } from 'react'
import HeroLink from '../../../components/HeroLink'
import Title from '../../../components/_typography/Title'
import Track from '../../../components/_typography/Track'
import { IRelease } from '../../../types/api'
import { getCurrentTrack } from '../Releases.utils'

interface ReleaseInfoProps {
    release?: IRelease
    currentTrackId: number | null
    setCurrentTrackId: Dispatch<SetStateAction<number | null>>
}

export default function ReleaseInfo({
    release,
    currentTrackId,
    setCurrentTrackId
}: ReleaseInfoProps) {
    if (!release || !currentTrackId) return null

    const currentTrack = getCurrentTrack(release, currentTrackId)

    return (
        <>
            <Title level="h1">{release.artist}</Title>

            <Title level="h2" hasBottomSpacing>
                {release.title}
            </Title>

            {release.tracks.map((track) => (
                <Track
                    isActive={track.id === currentTrack?.id}
                    key={track.id}
                    onClick={() => setCurrentTrackId(track.id)}
                >
                    {`${track.side}. ${release.artist} - ${track.title}`}
                </Track>
            ))}

            <Title hasTopSpacing>
                {`${release.release_type} ${release.year}`}
            </Title>

            <Title>{release.format}</Title>
            <Title hasBottomSpacing>{release.speed}</Title>

            {release.links?.map((link, i) => (
                <HeroLink
                    key={i}
                    {...link}
                    target="_blank"
                    hasBottomSpacing={i === release.links.length - 1}
                />
            ))}

            <div>{release.description}</div>
        </>
    )
}
