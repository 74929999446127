import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import mainReducer from '../mainReducer'

const isDevelopment = process.env.NODE_ENV === 'development'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}

export default () => {
    const composeEnhancers =
        isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            : compose

    const middleware = [thunk]

    const store = createStore(
        combineReducers({ mainReducer }),
        composeEnhancers(applyMiddleware(...middleware))
    )

    if (isDevelopment) {
        /** @ts-ignore */
        window.store = store
    }

    return store
}
