import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import styles from './MainNav.module.scss'
import { useSelector } from 'react-redux'
import { getLoadingState } from '../../mainReducer'
import Scroll from 'react-scroll'

interface MainNavProps {
    routes: any // FIXME: TS
    activeRoute: string
    setActiveRoute: (key: string) => void
}

export default function MainNav({
    routes,
    activeRoute,
    setActiveRoute
}: MainNavProps) {
    const isLoading = useSelector(getLoadingState)

    return (
        <nav
            className={classNames({
                [styles['MainNav']]: true,
                [styles['MainNav--is-visible']]: !isLoading
            })}
        >
            <ul className={styles['MainNav__items']}>
                {routes.map((route: any /** FIXME: TS */) => {
                    return (
                        <li className={styles['MainNav__item']} key={route.key}>
                            <NavLink
                                to={route.path}
                                className={styles['MainNav__link']}
                                exact
                                activeClassName={
                                    styles['MainNav__link--is-active']
                                }
                                onClick={() => {
                                    setActiveRoute(route.key)
                                    Scroll.animateScroll.scrollToTop()
                                }}
                            >
                                {route.key}
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}
