import React from 'react'
import Image from '../../../components/Image'
import { IMix } from '../../../types/api'

interface MixMediaProps {
    mix: IMix
}

export default function MixMedia({ mix }: MixMediaProps) {
    return (
        <>
            {mix.images?.map((image, i) => (
                <Image
                    key={i}
                    src={image.url}
                    width={image.width}
                    height={image.height}
                />
            ))}

            {mix.description}
        </>
    )
}
