import { connect } from 'react-redux'
import App from './App'
import { setActiveRoute } from './App.actions'

const stateProps = (state: any) /**FIXME: TS */ => ({
    title: state.mainReducer.title,
    activeRoute: state.mainReducer.activeRoute,
})

const dispatchProps = {
    setActiveRoute,
}

export default connect(stateProps, dispatchProps)(App)
