import React from 'react'
import { ILink } from '../../types/api'
import styles from './HeroLink.module.scss'
import classNames from 'classnames'

interface HeroLinkProps extends ILink {
    target?: '_blank',
    isSmall?: boolean,
    hasBottomSpacing?: boolean
}

export default function HeroLink({ title, url, target, isSmall, hasBottomSpacing }: HeroLinkProps) {
    return (
        <div className={styles['HeroLink']}>
            <a className={classNames({
                [styles['HeroLink__inner']]: true,
                [styles['HeroLink__inner--is-small']]: isSmall,
                [styles['HeroLink__inner--has-bottom-spacing']]: hasBottomSpacing
            })} href={url} target={target}>
                + {title}
            </a>
        </div>
    )
}
