import axios from 'axios'

import { Dispatch } from 'redux'
import { removeLoader } from '../../mainReducer'
import getServerBaseUrl from '../../utils/getServerBaseUrl'
import isStoreDataFetched from '../../utils/isStorDataFetched'

export const FETCH_MIXES = 'FETCH_MIXES'

const mixes = require("../../db/mixes.json");

export const fetchMixes = () => (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: FETCH_MIXES,
        payload: mixes
    })

    if (isStoreDataFetched(getState())) {
        /**@ts-ignore */
        dispatch(removeLoader())
    }
}
