import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import CollapseTrigger from '../CollapseTrigger'

import './ViewContainer.scss'

interface ViewContainerProps {
    navLabel?: string
    firstArea: JSX.Element
    secondArea: JSX.Element | null
    thirdArea: JSX.Element | null
    renderKey: string | number // used to trigger a transition
}

export default function ViewContainer({
    navLabel,
    firstArea,
    secondArea,
    thirdArea,
    renderKey
}: ViewContainerProps) {
    const isMobile = window.innerWidth < 1100
    const [isNavOpen, setIsNavOpen] = useState(false)

    const closeNavWithDelay = () => {
        setTimeout(() => {
            setIsNavOpen(false)
        }, 1000)
    }

    return (
        <div className="ViewContainer">
            <aside className="ViewContainer__area is-first">
                <div className="ViewContainer__area-inner is-first">
                    {isMobile && navLabel ? (
                        <>
                            <CollapseTrigger
                                label={navLabel}
                                onClick={() => setIsNavOpen(!isNavOpen)}
                                isOpen={isNavOpen}
                            />

                            <Collapsible
                                trigger=""
                                easing="cubic-bezier(0.41, 0.02, 0.1, 0.98)"
                                open={isNavOpen}
                            >
                                <div onClick={() => closeNavWithDelay()}>
                                    {firstArea}
                                </div>
                            </Collapsible>
                        </>
                    ) : (
                        firstArea
                    )}
                </div>
            </aside>

            <section className="ViewContainer__area is-second">
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={renderKey}
                        timeout={{ exit: 500 + 80, enter: 500 }}
                        classNames="inner-transition"
                    >
                        <div className="ViewContainer__area-inner">
                            {secondArea}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </section>

            <section className="ViewContainer__area is-third">
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        key={renderKey}
                        timeout={{ exit: 500 + 80 * 2, enter: 500 }}
                        classNames="inner-transition"
                    >
                        <div className="ViewContainer__area-inner is-third">
                            {thirdArea}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </section>
        </div>
    )
}
