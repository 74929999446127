import axios from 'axios'
import { Dispatch } from 'redux'
import { removeLoader } from '../../mainReducer'
import getServerBaseUrl from '../../utils/getServerBaseUrl'
import isStoreDataFetched from '../../utils/isStorDataFetched'

export const FETCH_RELEASES = 'FETCH_RELEASES'

const releases = require("../../db/releases.json");

export const fetchReleases = () => (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: FETCH_RELEASES,
        payload: releases
    })

    if (isStoreDataFetched(getState())) {
        /**@ts-ignore */
        dispatch(removeLoader())
    }
}
