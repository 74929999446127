import React from 'react'
import Title from '../../../components/_typography/Title'
import HeroLink from '../../../components/HeroLink'

export default function BookingInfo() {
    return (
        <>
            <Title level="h1">inquiries</Title>

            <HeroLink
                id={0}
                title="sewer&#8203;.sender&#8203;@&#8203;gmail&#8203;.com"
                url="mailto:sewer.sender@gmail.com"
            />
        </>
    )
}
