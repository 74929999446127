import React, {
    MouseEvent,
    ReactElement,
    useEffect,
    useRef,
    useState
} from 'react'
import ReactPlayer from 'react-player'
import Pause from '../_icons/Pause'
import Play from '../_icons/Play'
import Skip from '../_icons/Skip'
import styles from './AudioPlayer.module.scss'

interface AudioPlayerProps {
    src: string
    title: string | ReactElement
    onPrevClick?: () => void
    onNextClick?: () => void
}

export default function AudioPlayer({
    src,
    title,
    onPrevClick,
    onNextClick
}: AudioPlayerProps) {
    const [isPlaying, setIsPlaying] = useState(false)
    const [isScrubbing, setIsScrubbing] = useState(false)
    const [duration, setDuration] = useState(0)
    const [progress, setProgress] = useState(0)
    const [player, setPlayer] = useState<ReactPlayer | null>(null)

    function togglePlayState() {
        setIsPlaying(!isPlaying)
    }

    function getAndSetSeekPosition(e: MouseEvent) {
        const target = e.target as HTMLDivElement
        const elWidth = target.offsetWidth
        const rect = target.getBoundingClientRect()
        const x = e.clientX - rect.left
        const scrubPosition = x / elWidth

        seekTo(scrubPosition)
    }

    function seekTo(fraction: number) {
        setProgress(fraction)
        player?.seekTo(fraction)
    }

    function handleEnd() {
        setTimeout(() => {
            setIsPlaying(false)
            seekTo(0)
        }, 2000)
    }

    function formatDuration(seconds: number) {
        const secondsRounded = Math.round(seconds)
        const isOverAnHour = secondsRounded > 3600
        if (isOverAnHour) {
            return `${new Date(secondsRounded * 1000)
                .toISOString()
                .substr(11, 8)}`
        } else {
            return `${new Date(secondsRounded * 1000)
                .toISOString()
                .substr(14, 5)}`
        }
    }

    return (
        <div className={styles['AudioPlayer']}>
            <ReactPlayer
                ref={(ref) => setPlayer(ref)}
                url={src}
                playing={isScrubbing ? false : isPlaying}
                onProgress={(val) => setProgress(val.played)}
                onDuration={(seconds) => setDuration(seconds)}
                onEnded={() => handleEnd()}
                height={0}
            />

            <div>{title}</div>

            <div
                className={styles['AudioPlayer__track']}
                onMouseDown={() => setIsScrubbing(true)}
                onMouseMove={(e) => isScrubbing && getAndSetSeekPosition(e)}
                onMouseOut={(e) => {
                    if (isScrubbing) {
                        setIsScrubbing(false)
                        setIsPlaying(false)
                    }
                }}
                onMouseUp={(e) => {
                    setIsScrubbing(false)
                    getAndSetSeekPosition(e)
                }}
            >
                <div className={styles['AudioPlayer__runway']} />
                <div
                    className={styles['AudioPlayer__elapsed']}
                    style={{ width: `${100 * progress}%` }}
                >
                    <div className={styles['AudioPlayer__handle']} />
                </div>
            </div>

            <div className={styles['AudioPlayer__times']}>
                <div className={styles['AudioPlayer__time-start']}>
                    {formatDuration(duration * progress)}
                </div>
                <div className={styles['AudioPlayer__time-end']}>
                    {formatDuration(duration)}
                </div>
            </div>

            <div className={styles['AudioPlayer__controls']}>
                {onPrevClick && (
                    <button
                        className={styles['AudioPlayer__prev']}
                        onClick={() => {
                            seekTo(0)
                            onPrevClick()
                        }}
                    >
                        <Skip />
                    </button>
                )}
                <button
                    className={styles['AudioPlayer__play-pause']}
                    onClick={() => togglePlayState()}
                >
                    {isPlaying ? <Pause /> : <Play />}
                </button>
                {onNextClick && (
                    <button
                        className={styles['AudioPlayer__next']}
                        onClick={() => {
                            seekTo(0)
                            onNextClick()
                        }}
                    >
                        <Skip />
                    </button>
                )}
            </div>
        </div>
    )
}
