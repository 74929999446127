import { IRelease } from '../../types/api'

export function getCurrentTrack(release: IRelease, selectedId: null | number) {
    return selectedId
        ? release.tracks.find((track) => track.id === selectedId)
        : null
}

export function getPrevTrackId(
    release: IRelease,
    currentTrackId: null | number
) {
    const idx = release.tracks.findIndex((track) => track.id === currentTrackId)
    const prevIdx = idx === 0 ? release.tracks.length - 1 : idx - 1
    return release.tracks[prevIdx].id
}

export function getNextTrackId(
    release: IRelease,
    currentTrackId: null | number
) {
    const idx = release.tracks.findIndex((track) => track.id === currentTrackId)
    const nextIdx = (idx + 1) % release.tracks.length
    return release.tracks[nextIdx].id
}
