import React from 'react'
import Image from '../../../components/Image'
import { IEvent } from '../../../types/api'

interface DateImagesProps {
    date: IEvent
}

export default function DateImages({ date }: DateImagesProps) {
    return (
        <>
            {date.images?.map((image, i) => (
                <Image
                    key={i}
                    src={image.url}
                    width={image.width}
                    height={image.height}
                />
            ))}
        </>
    )
}
