import React, { Dispatch, SetStateAction } from 'react'
import AudioPlayer from '../../../components/AudioPlayer'
import Track from '../../../components/_typography/Track'
import Image from '../../../components/Image'
import { IRelease } from '../../../types/api'
import {
    getCurrentTrack,
    getNextTrackId,
    getPrevTrackId
} from '../Releases.utils'

interface ReleaseMediaProps {
    release: IRelease
    artist: string
    currentTrackId: number | null
    setCurrentTrackId: Dispatch<SetStateAction<number | null>>
}

export default function ReleaseMedia({
    release,
    artist,
    currentTrackId,
    setCurrentTrackId
}: ReleaseMediaProps) {
    if (!release || !currentTrackId) return null

    const currentTrack = getCurrentTrack(release, currentTrackId)
    const prevTrackId = getPrevTrackId(release, currentTrackId)
    const nextTrackId = getNextTrackId(release, currentTrackId)

    function TrackTitle() {
        if (!currentTrack) return null

        return (
            <>
                <Track>{currentTrack.side}</Track>
                <Track>{artist}</Track>
                <Track>{currentTrack.title}</Track>
            </>
        )
    }

    return (
        <>
            {currentTrack?.image && (
                <div
                    style={{
                        width: '80% ',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Image
                        src={currentTrack.image.url}
                        width={currentTrack.image.width}
                        height={currentTrack.image.height}
                    />
                </div>
            )}

            {/* {release.images?.map((image, i) => (
                <div
                    style={{
                        width: '80% ',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Image
                        key={i}
                        src={image.url}
                        width={image.width}
                        height={image.height}
                    />
                </div>
            ))} */}

            {currentTrack && (
                <AudioPlayer
                    title={<TrackTitle />}
                    src={currentTrack.url}
                    onPrevClick={() => setCurrentTrackId(prevTrackId)}
                    onNextClick={() => setCurrentTrackId(nextTrackId)}
                />
            )}
        </>
    )
}
