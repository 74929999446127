import React from 'react'
import HeroLink from '../../../components/HeroLink'
import Title from '../../../components/_typography/Title'
import { IMix } from '../../../types/api'
import parseDate from '../../../utils/parseDate'

interface MixInfoProps {
    mix: IMix
}

export default function MixInfo({ mix }: MixInfoProps) {
    return (
        <>
            <Title level="h1" hasBottomSpacing>
                {mix.artist}
            </Title>

            <Title level="h2" hasBottomSpacing>
                {mix.title}
            </Title>

            <Title>
                {parseDate(mix.date)}
            </Title>

            <Title hasBottomSpacing>{mix.channel}</Title>

            {mix.links?.map((link, i) => (
                <HeroLink
                    key={i}
                    {...link}
                    target="_blank"
                    hasBottomSpacing={i === mix.links.length - 1}
                />
            ))}
        </>
    )
}
