import React, { useEffect, useState } from 'react'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import LinkList from '../../components/LinkList'
import { useSelector } from 'react-redux'
import { getDates } from './Dates.selectors'
import { IEvent } from '../../types/api'
import { ILinkListLink } from '../../components/LinkList/LinkList'
import DateInfo from './DateInfo'
import DateImages from './DateImages'
import parseDate from '../../utils/parseDate'

// FIXME: This file is a mess
export default function Dates() {
    const dates = useSelector(getDates)
    const [selectedDate, setSelectedDate] = useState<IEvent>(dates?.[0])

    function createDateListData(dates: IEvent[]): ILinkListLink[] {
        const parsedDates = dates.map((date: IEvent) => {
            const d = new Date(date.date)

            return {
                id: date.id,
                label: parseDate(d),
                unixTime: d.getTime(),
                listSelectId: date.id,
                onClick: () => setSelectedDate(date)
            }
        })

        return parsedDates.sort((a, b) => b.unixTime - a.unixTime)
    }

    const upcomingDates: IEvent[] = []
    const pastDates: IEvent[] = []

    const now = Date.now()

    dates?.forEach((date: IEvent) => {
        const eventDate = Date.parse(date.date)

        if (eventDate > now) {
            upcomingDates.push(date)
        } else {
            pastDates.push(date)
        }

        if (date.date > selectedDate.date) {
            setSelectedDate(date)
        }
    })

    const parsedPastDates = createDateListData(pastDates)
    const parsedUpcomingDates = createDateListData(upcomingDates)

    return (
        <ViewContainer
            navLabel={parseDate(selectedDate.date)}
            renderKey={selectedDate?.id}
            firstArea={
                <>
                    <LinkList
                        title="Upcoming"
                        items={parsedUpcomingDates}
                        selected={selectedDate?.id}
                    />
                    <LinkList
                        title="Past"
                        items={parsedPastDates}
                        selected={selectedDate?.id}
                    />
                </>
            }
            secondArea={selectedDate && <DateInfo date={selectedDate} />}
            thirdArea={selectedDate && <DateImages date={selectedDate} />}
        />
    )
}
