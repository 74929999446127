import React from 'react'

interface ArrowProps {}

export default function Arrow({}: ArrowProps) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5559 26.7844L14.5559 1.35001H15.5559L15.5559 26.6771L25.9624 16.2706L26.6695 16.9777L15.0022 28.6449L3.33496 16.9777L4.04207 16.2706L14.5559 26.7844Z"
                fill="white"
            />
        </svg>
    )
}
