import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import MainNav from '../../components/MainNav/MainNav'
import Releases from '../../views/Releases'
import Dates from '../../views/Dates'
import Mixes from '../../views/Mixes'
import Booking from '../../views/Booking'

import './App.scss'
import { useDispatch, useSelector } from 'react-redux'
import { fetchReleases } from '../../views/Releases/Releases.actions'
import { fetchMixes } from '../../views/Mixes/Mixes.actions'
import { fetchDates } from '../../views/Dates/Dates.actions'
import Loader from '../../components/Loader'
import { getLoadingState } from '../../mainReducer'

const routes = [
    {
        key: 'Releases',
        path: '/',
        component: Releases,
        exact: true
    },
    {
        key: 'Dates',
        path: '/dates',
        component: Dates
    },
    {
        key: 'Mixes',
        path: '/mixes',
        component: Mixes
    },
    {
        key: 'Booking',
        path: '/booking',
        component: Booking
    }
]

interface ContainerProps {
    location: any // TSFixme
}

function Container({ location }: ContainerProps) {
    const isLoading = useSelector(getLoadingState)

    return (
        <main className="App__container">
            {isLoading && <Loader />}

            <TransitionGroup className="App__transition-group">
                {!isLoading && (
                    <CSSTransition
                        key={location.key}
                        timeout={{ enter: 2000, exit: 2000 }}
                        classNames="transition"
                        in={!isLoading}
                        appear={true}
                    >
                        <section className="App__route-section">
                            <Switch location={location}>
                                {routes.map((route) => (
                                    /** @ts-ignore */
                                    <Route {...route} />
                                ))}
                            </Switch>
                        </section>
                    </CSSTransition>
                )}
            </TransitionGroup>
        </main>
    )
}

const ContainerWithRouter = withRouter(Container)

interface AppProps {
    title: string
    activeRoute: string // FIXME: Add enum
    setActiveRoute: any // FIXME: TS
}

export default function App({ title, activeRoute, setActiveRoute }: AppProps) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchReleases())
        dispatch(fetchDates())
        dispatch(fetchMixes())
    }, [])

    return (
        <Router>
            <div className="App">
                <ContainerWithRouter />
                <MainNav
                    routes={routes}
                    activeRoute={activeRoute}
                    setActiveRoute={setActiveRoute}
                />
            </div>
        </Router>
    )
}
