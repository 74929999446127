import React from 'react'
import Image from '../../../components/Image'

export default function BookingMedia() {
    return (
        <>
            <Image
                src="https://dl.dropboxusercontent.com/s/baso3zsu6fehyd1/sewer%20version.png"
                width="1000"
                height="1000"
            />
        </>
    )
}
