import React, { useEffect, useState } from 'react'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import LinkList from '../../components/LinkList'
import { useSelector } from 'react-redux'
import { getReleases } from './Releases.selectors'
import { IRelease } from '../../types/api'
import ReleaseInfo from './ReleaseInfo'
import ReleaseMedia from './ReleaseMedia'

export default function Releases() {
    const releases = useSelector(getReleases)
    const [navLabel, setNavLabel] = useState('Releases')

    const [selectedRelease, setSelectedRelease] = useState<IRelease>(
        releases?.[0]
    )
    const [currentTrackId, setCurrentTrackId] = useState<null | number>(null)

    useEffect(() => {
        if (!selectedRelease && releases.length) {
            setSelectedRelease(releases[0])
        }
    }, [releases])

    useEffect(() => {
        if (selectedRelease) {
            setCurrentTrackId(selectedRelease.tracks[0].id)
        }

        setNavLabel(selectedRelease.cat_nr)
    }, [selectedRelease])

    const releaseList =
        releases?.length &&
        releases.map((release: IRelease) => ({
            label: release.cat_nr,
            listSelectId: release.cat_nr,
            onClick: () => setSelectedRelease(release)
        }))

    return (
        <ViewContainer
            navLabel={navLabel}
            renderKey={selectedRelease?.cat_nr}
            firstArea={
                <LinkList
                    items={releaseList}
                    selected={selectedRelease?.cat_nr}
                />
            }
            secondArea={
                selectedRelease && (
                    <ReleaseInfo
                        release={selectedRelease}
                        currentTrackId={currentTrackId}
                        setCurrentTrackId={setCurrentTrackId}
                    />
                )
            }
            thirdArea={
                selectedRelease && (
                    <ReleaseMedia
                        release={selectedRelease}
                        artist={selectedRelease.artist}
                        currentTrackId={currentTrackId}
                        setCurrentTrackId={setCurrentTrackId}
                    />
                )
            }
        />
    )
}
