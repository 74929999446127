import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import { getMixes } from './Mixes.selectors'
import { IMix } from '../../types/api'
import LinkList from '../../components/LinkList'
import MixInfo from './MixInfo'
import MixMedia from './MixMedia'
import parseDate from '../../utils/parseDate'
import { setActiveNavLabel } from '../../modules/App/App.actions'

export default function Mixes() {
    const dispatch = useDispatch()
    const mixes = useSelector(getMixes)
    const [selectedMix, setSelectedMix] = useState<IMix>(mixes?.[0])
    const [navLabel, setNavLabel] = useState('Mixes')

    useEffect(() => {
        if (!selectedMix && mixes.length) {
            setSelectedMix(mixes[0])
        }
    }, [mixes])

    useEffect(() => {
        if (selectedMix) {
            setNavLabel(parseDate(selectedMix.date))
        } else {
            setNavLabel("Mixes")
        }
    }, [selectedMix])

    const mixList =
        mixes?.length &&
        mixes
            .map((mix: IMix) => {
                const d = new Date(mix.date)

                return {
                    label: parseDate(d),
                    listSelectId: mix.id,
                    unixTime: d.getTime(),
                    onClick: () => setSelectedMix(mix)
                }
            })
            .sort((a: any, b: any) => b.unixTime - a.unixTime)

    return (
        <>
            {
                (mixes?.length > 0) ? 
                <ViewContainer
                    renderKey={selectedMix?.id}
                    navLabel={navLabel}
                    firstArea={<LinkList items={mixList} selected={selectedMix?.id} />}
                    secondArea={selectedMix && <MixInfo mix={selectedMix} />}
                    thirdArea={selectedMix && <MixMedia mix={selectedMix} />}
                /> :
                <div>TBA</div>
            }
        </>
    )
}
