import React from 'react'
import { useSelector } from 'react-redux'
import { getLoaderVisibilityState } from '../../mainReducer'
import styles from './Loader.module.scss'
import classNames from 'classnames'
import Clockwork from '../_loaders/Clockwork'

interface LoaderProps {}

export default function Loader({}: LoaderProps) {
    const showLoader = useSelector(getLoaderVisibilityState)

    return (
        <div
            className={classNames({
                [styles['Loader']]: true,
                [styles['Loader--is-hidden']]: !showLoader
            })}
        >
            <Clockwork isHidden={!showLoader} />
        </div>
    )
}
