import axios from 'axios'
import { Dispatch } from 'redux'
import { removeLoader } from '../../mainReducer'
import getServerBaseUrl from '../../utils/getServerBaseUrl'
import isStoreDataFetched from '../../utils/isStorDataFetched'

export const FETCH_DATES = 'FETCH_DATES'

const dates = require("../../db/events.json");

export const fetchDates = () => (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: FETCH_DATES,
        payload: dates
    })

    if (isStoreDataFetched(getState())) {
        /**@ts-ignore */
        dispatch(removeLoader())
    }
}
