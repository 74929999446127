import React from 'react'
import styles from './Clockwork.module.scss'
import layer1 from './images/layer-1.png'
import layer2 from './images/layer-2.png'
import layer3 from './images/layer-3.png'
import layer4 from './images/layer-4.png'
import layer5 from './images/layer-5.png'
import layer6 from './images/layer-6.png'
import layer7 from './images/layer-7.png'
import classNames from 'classnames'

interface ClockworkProps {
    isHidden?: boolean
}

export default function Clockwork({ isHidden }: ClockworkProps) {
    return (
        <div
            className={classNames({
                [styles['Clockwork']]: true,
                [styles['Clockwork--is-hidden']]: isHidden
            })}
        >
            <img className={styles['Clockwork__image']} src={layer1} />
            <img className={styles['Clockwork__image']} src={layer2} />
            <img className={styles['Clockwork__image']} src={layer3} />
            <img className={styles['Clockwork__image']} src={layer4} />
            <img className={styles['Clockwork__image']} src={layer5} />
            <img className={styles['Clockwork__image']} src={layer6} />
            <img className={styles['Clockwork__image']} src={layer7} />
        </div>
    )
}
