import classNames from 'classnames'
import React from 'react'
import Arrow from '../_icons/Arrow'
import './CollapseTrigger.scss'

interface CollapseTriggerProps {
    label?: string
    isOpen: boolean
    onClick: () => void
}

export default function CollapseTrigger({ label, isOpen, onClick }: CollapseTriggerProps) {

    return (
        <button className={classNames([
            'CollapseTrigger',
            isOpen && 'is-open',
            !isOpen && 'is-closed'
        ])} onClick={onClick}>
            <div className="CollapseTrigger__label">{label}</div>

            <div className="CollapseTrigger__arrow">
                <Arrow />
            </div>
        </button>
    )
}