import React from 'react'
import HeroLink from '../../../components/HeroLink'
import Title from '../../../components/_typography/Title'
import { IEvent } from '../../../types/api'

interface DateInfoProps {
    date: IEvent
}

export default function DateInfo({ date }: DateInfoProps) {
    return (
        <>
            <Title
                level="h1"
                hasBottomSpacing
            >{`${date.title}`}</Title>

            <Title level="h2" hasBottomSpacing>
                {date.location}
            </Title>

            {date.links?.map((link, i) => (
                <HeroLink
                    key={i}
                    {...link}
                    target="_blank"
                    hasBottomSpacing={i === date.links.length - 1}
                />
            ))}

            <p>{date.description}</p>
        </>
    )
}
