import { SET_NAV_LABEL } from "../../mainReducer"

export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE'

export function setActiveRoute(routeKey: string) {
    return {
        type: SET_ACTIVE_ROUTE,
        payload: routeKey,
    }
}

export function setActiveNavLabel(label: string) {
    return {
        type: SET_NAV_LABEL,
        payload: label
    }
}
