import React from 'react'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import BookingInfo from './BookingInfo'
import BookingMedia from './BookingMedia'

export default function Booking() {
    return (
        <ViewContainer
            renderKey="booking"
            firstArea={<></>}
            secondArea={<BookingInfo />}
            thirdArea={<BookingMedia />}
        />
    )
}
